/* JS */
/* react */
import React from 'react';
import Lottie from 'react-lottie';

import AOS from 'aos';

import ATLayout from '../components/ATLayout';
import ATPartner from '../components/ATPartner';
import Hamburger from '../components/Hamburger/Hamburger';
import OurNumbers from '../components/OurNumbers/OurNumbers';
import ButtonNext from '../components/Button/ButtonNext';
import SEO from '../components/seo';
import Footer2020 from '../components/Footer2020/Footer2020';
import Footer from '../components/Footer/Footer'






import * as animationData from '../animations/space.json';



import 'aos/dist/aos.css';

import './informativa-privacy.scss';



class InformativaPrivacyPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isMenuOpen: false,
			selectedService: '',
			width: 0,
		};

		this.handleOnHamburgerClick = this.handleOnHamburgerClick.bind(this);

		this.handleOnServiceClick = this.handleOnServiceClick.bind(this);
		this.handleOnServiceItemClick = this.handleOnServiceItemClick.bind(this);

		this.handleOnArrowMouseEnter = this.handleOnArrowMouseEnter.bind(this);
		this.handleOnArrowMouseLeave = this.handleOnArrowMouseLeave.bind(this);

		this.handleOnOutsideMenuClick = this.handleOnOutsideMenuClick.bind(this);

		this.handleOnWindowSizeChange = this.handleOnWindowSizeChange.bind(this);
	}

	componentDidMount() {
		this.handleOnWindowSizeChange();
		window.addEventListener('resize', this.handleOnWindowSizeChange);

		AOS.init();
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleOnWindowSizeChange);
	}

	componentDidUpdate() {
		AOS.refresh();
	}

	handleOnHamburgerClick(event) {
		let imo = this.state.isMenuOpen;
		this.setState({
			isMenuOpen: !imo,
		});
	}

	handleOnArrowMouseEnter(event) {
		let arrow = event.target;
		console.log(arrow.parentNode.getElementsByTagName('h6'));
		arrow.parentNode.getElementsByTagName('h6')[0].classList = 'service-button-text animated fadeInRight';

		/*if (text) {
			var className = "fadeOutRight";
			var className2 = "fadeInRight";

			text.classList.remove(className);
			text.classList.add(className2);
		}*/
	}

	handleOnArrowMouseLeave(event) {
		let arrow = event.target;
		console.log(arrow.parentNode.getElementsByTagName('h6'));
		arrow.parentNode.getElementsByTagName('h6')[0].classList = 'service-button-text animated fadeOutRight';

		/*if (text) {
			var className = "fadeInRight";
			var className2 = "fadeOutRight";

			text.classList.remove(className);
			text.classList.add(className2);
		}*/
	}

	handleOnServiceClick(event) {
		let service = event.currentTarget.parentNode.parentNode.parentNode.getAttribute('data-service');
		if (service && this.state.selectedService !== service)
			this.setState(
				{
					selectedService: service,
					selectedServiceItem: 0,
				},
				() => AOS.refresh()
			);
		else
			this.setState(
				{
					selectedService: '',
				},
				() => AOS.refresh()
			);
	}

	handleOnServiceItemClick(event) {
		const li = event.currentTarget;
		const parent = li.parentNode;
		const index = Array.prototype.indexOf.call(parent.children, li);
		this.setState({ selectedServiceItem: index });
	}

	handleOnOutsideMenuClick(event) {
		this.setState({
			isMenuOpen: false,
		});
	}

	handleOnWindowSizeChange() {
		this.setState({ width: window.innerWidth });
	}

	render() {
		const isMobile = this.state.width <= 812;

		return (
			<ATLayout title="Informativa sulla privacy" isMenuOpen={this.state.isMenuOpen} onOutsideMenuClick={this.handleOnOutsideMenuClick}>
				<SEO
					title="Informativa sulla privacy"
					relPath="informativa-privacy"
					description="Informativa sulla privacy di Alfatauri Studio."
				/>
				<div className="at-page at-privacy">
					<div className="container">
						<div className="col-xs-12 col-sm-12 col-md-10">



							<h1>Informativa sulla privacy<span style={{ color: '#ea6910' }}>.</span></h1>
							<p>
								Ai sensi e per gli effetti del combinato disposto del Decreto Legislativo 196/2003 (Codice Privacy) e del Regolamento Europeo 2016/679 (Regolamento Generale sulla Protezione dei Dati o GDPR), l’attività Alfatauri di Curcio Andrea con sede in Piazza Tommaso Campanella, 6 88046 Lamezia Terme, in qualità di Titolare del trattamento (di seguito Titolare), informa di quanto segue.
<br></br>
								<br></br>
La presente informativa sulla privacy à resa disponibile e valida per il sito internet https://www.alfatauristudio.com.
<br></br>
								<br></br>
Art. 1. Titolare del trattamento (la persona o l'azienda che decide come e perchà trattare i dati).
Alfatauri di Curcio Andrea || Piazza Tommaso Campanella, 6 88046 Lamezia Terme | Partita IVA: IT03666510791 | Codice Fiscale: CRCNDR95T29M208H | email: info@alfatauristudio.com | PEC: andreacurcio@pecaruba.it .
<br></br>
								<br></br>
Art. 2. Finalità (lo scopo per cui raccogliamo i dati) e base giuridica (se un dato à o meno obbligatorio per dare esecuzione al contratto, o se i dati necessitano il vostro specifico consenso per essere utilizzati).
<br></br>
								<br></br>
Il Titolare svolge l'attività di progettazione e sviluppo software.
Il presente sito internet non tiene traccia e non raccoglie dati degli utenti connessi. à presente un form contatti che permette agli interessati di comunicare direttamente con il titolare del trattamento. I dati trasmessi tramite form di contatto non sono archiviati automaticamente in database e non sono rilevati dati dei cookie presenti sui computer degli utenti.
L'invio facoltativo, esplicito e volontario tramite la compilazione del form di contatto comporta la successiva acquisizione dell'indirizzo email del mittente necessario per rispondere alle richieste, nonchà degli eventuali altri dati personali inseriti facoltativamente dall'interessato stesso. I campi obbligatori e necessari per poter rispondere alle richieste degli interessati sono: Nome, Indirizzo email e messaggio.
Non viene richiesto un esplicito consenso al trattamento dei dati in quanto c'à un legittimo interesse del Titolare ad utilizzare le informazioni fornite volontariamente dall'interessato al fine di raggiungere le seguenti finalità
<br></br>
								<br></br>
- rispondere alle richieste inviate;
- emettere preventivi, formulare proposte di contratto;
- adempiere agli obblighi previsti dalla legge, da un regolamento, dalla normativa comunitaria o da un ordine dell'Autorità;
- esercitare i diritti del Titolare, ad esempio l'eventuale diritto di difesa in giudizio.
<br></br>
								<br></br>
Art. 3. Durata del trattamento (per quanto tempo conserviamo i dati).
Fatto salvo il termine di conservazione previsto dalle specifiche normative (es. fiscale, antiriciclaggio, ecc.), per l'intera durata del contratto e per l'intera durata di un eventuale contenzioso, il titolare del trattamento conserva i dati raccolti per un periodo massimo di 3 (tre) anni dal termine del rapporto con gli Interessati, in modo da per poter rispondere ad eventuali richieste degli stessi.
<br></br>
								<br></br>
Art. 4. Modalità del trattamento (come usiamo i dati).
Il trattamento à effettuato nei limiti strettamente necessari al raggiungimento delle finalità di cui sopra (vedi Art. 2), nel rispetto del principio di proporzionalità nella scelta delle modalità di ripresa e dislocazione e con l'adozione di tutte le misure idonee a garantire la sicurezza e la riservatezza dei dati personali degli interessati.
<br></br>
								<br></br>
Art. 5. Dove si trovano i dati raccolti?
I dati sono trattati e archiviati presso la sede del Titolare e sugli strumenti aziendali utilizzati (es. computer). Tutti i dati (cartacei e digitali) sono protetti da adeguati sistemi di sicurezza in modo da garantirne la riservatezza e la salvaguardia. Tutti i dati sono fisicamente conservati in Italia. Alcuni file digitali possono essere archiviati in sistemi cloud. I fornitori sono stati selezionati in modo da garantire la salvaguardia e la riservatezza dei dati. Questi dispositivi sono fisicamente posizionati all'interno dell'Unione Europea.
<br></br>
								<br></br>
Art. 6. A chi sono comunicati i dati?
I dati non sono soggetti a comunicazione e diffusione a terzi, salvi gli obblighi derivanti dalla legge. In adempimento a tali obblighi i dati dei clienti possono essere trasmessi a soggetti terzi che effettuano il trattamento per conto del Titolare nella loro qualità di Responsabili esterni nominati ai sensi dell'art. 28 GDPR (a titolo indicativo il commercialista per i dati relativi alla fatturazione), agli Istituti di Credito, a dipendenti e/o collaboratori del Titolare nell'espletamento della loro normale attività di lavoro e/o di collaborazione, quali persone autorizzate al trattamento, il cui elenco aggiornato à comunque disponibile presso gli uffici del medesimo.
<br></br>
								<br></br>
Art. 7. Diritti degli interessati (Artt. 15 e seguenti del GDPR).
Art. 15: Diritto di accesso, comprensivo del diritto di ottenere l'indicazione del periodo di conservazione dei dati personali previsto, oppure se non à possibile i criteri utilizzati per determinare tale periodo. Diritto di ottenere indicazione sull'origine dei dati raccolti, nonchà delle finalità e delle modalità di trattamento. Diritto di proporre in qualsiasi momento reclamo all'Autorità di controllo: Garante Privacy: Piazza di Monte Citorio nr. 121, 00186 ROMA Tel. +39 06 696771 - PEC: protocollo@pec.gpdp.it
<br></br>
								<br></br>
Art. 16: Diritto dell'interessato di ottenere l'aggiornamento, la rettifica ovvero l'integrazione dei dati personali.
Art. 17: Diritto alla cancellazione e diritto all'oblio.
Art. 18: Diritto di limitazione di trattamento, quando previsto.
Art. 19: Obbligo del titolare di notificare la rettifica, cancellazione e/o limitazione
Art. 20: Diritto alla portabilità dei dati, qualora la tecnologia in essere lo permetta.
<br></br>
								<br></br>
Art. 8. Istanze degli interessati.
Le istanze di cui al precedente Art. 7 potranno essere presentate dagli Interessati al Titolare del trattamento tramite lettera raccomandata o PEC (Posta elettronica certificata) agli indirizzi riportati al precedente Art. 1. In tutti i casi gli interessati dovranno allegare alla richiesta un proprio documento d'identità in corso di validità.
							</p>
						</div>


					</div>



					<Hamburger
						open={this.state.isMenuOpen} //className={this.props.isMenuOpen ? 'open' : ''}
						onClick={this.handleOnHamburgerClick}
					/>

					{/*	<Footer links={this.props.footerLinks} onLinkClick={this.props.onFooterLinkClick} /> */}
					<Footer2020 />
				</div>
			</ATLayout>
		);
	}
}

export default InformativaPrivacyPage;
